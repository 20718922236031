<template>
  <div> Bienvenido al home <router-link class="p-2 bg-blue-400 white-text rounded" :to="{name:'Login'}"> Ingresar</router-link></div>
</template>

<script>
export default {

};
</script>

<style>

</style>
