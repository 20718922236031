<template>
    <div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName ? route.meta.transitionName : 'slide'" mode="out-in">
                <component :is="Component" :key="$route.path" />
            </transition>
        </router-view>
  </div>

</template>

<style lang="css">
@import '../../assets/css/main.css';

/* base */

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-enter-from,
  .slide-leave-to{
    opacity: 0;
    transform: translateX(-5%);
  }

/* base */

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-right-enter-from,
  .slide-right-leave-to{
    opacity: 0;
    transform: translateX(30%);
  }

  .slide-left-enter-from,
  .slide-left-leave-to{
    opacity: 0;
    transform: translateX(-30%);
  }

</style>
